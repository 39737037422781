import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import { THE_BRIDE } from '@/constants';
import { BG_DESKTOP_VIEW } from '@/constants/assets';
import WithAnimation from '@/components/Common/WithAnimation';

function LeftContent() {
  return (
    <Box
      bgColor="bgPrimary"
      width="calc(100% - 500px)"
      height="100%"
      pos="fixed"
      bgImage={BG_DESKTOP_VIEW}
      bgSize="cover"
      bgPos="left"
      borderRight="8px solid"
      borderRightColor="blackAlpha.400"
    >
      <Box bgImage="linear-gradient(to bottom, #00000080, transparent)"
        padding="42px"
        height="350px"
      >
        <WithAnimation>
          <Text letterSpacing="4px" fontSize="xs" color="white" textShadow="1px 1px 2px #242424a8">
            THE WEDDING OF
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Heading
            margin="8px 0"
            fontWeight="normal"
            size="4xl"
            letterSpacing="2px"
            color="white"
            fontFamily="body"
            textTransform="uppercase"
            dangerouslySetInnerHTML={{ __html: THE_BRIDE.replace('&', '& <br />') }}
          />
        </WithAnimation>
        <WithAnimation>
          <Text fontStyle="italic" maxWidth="500px" color="white" textShadow="1px 1px 2px #242424a8">
          {`“Love starts as a feeling, but to continue is a choice; and I find myself choosing you more and more every day.”`}
              <br/> – Justin Wetch
          </Text>
        </WithAnimation>
      </Box>
    </Box>
  );
}

export default LeftContent;
